import draw2d from 'draw2d'

var locators = {};


/* TOP */
locators.MyInputPortLocator = draw2d.layout.locator.PortLocator.extend({
    NAME: 'MyInputPortLocator',
    init: function () {
        this._super();
    },
    relocate: function (index, figure) {
        this.applyConsiderRotation(figure, figure.getParent().getWidth() / 2, 0);
    }
});

/* BOTTOM */
locators.MyOutputPortLocator = draw2d.layout.locator.PortLocator.extend({
    NAME: 'MyOutputPortLocator',
    init: function () {
        this._super();
    },
    relocate: function (index, figure) {
        var p = figure.getParent();

        this.applyConsiderRotation(figure, p.getWidth() / 2, p.getHeight() - 0);
    }
});
/* RIGHT */
locators.MyOutputLeftPortLocator = draw2d.layout.locator.PortLocator.extend({
    NAME: 'MyOutputLeftPortLocator',
    init: function () {
        this._super();
    },
    relocate: function (index, figure) {
        var p = figure.getParent();

        this.applyConsiderRotation(figure, p.getWidth(), (p.getHeight() / 2));
    }
});
/* LEFT */
locators.MyInputRightPortLocator = draw2d.layout.locator.PortLocator.extend({
    NAME: 'MyInputRightPortLocator',
    init: function () {
        this._super();
    },
    relocate: function (index, figure) {
        this.applyConsiderRotation(figure, 0, figure.getParent().getHeight() / 2);
    }
});
/* TOP RIGHT */
locators.MyInputTopRightPortLocator = draw2d.layout.locator.PortLocator.extend({
    NAME: 'MyInputTopRightPortLocator',
    init: function () {
        this._super();
    },
    relocate: function (index, figure) {
        this.applyConsiderRotation(figure, figure.getParent().getWidth(), 0);
    }
});
window.MyInputPortLocator = locators.MyInputPortLocator;
window.MyOutputPortLocator = locators.MyOutputPortLocator;
window.MyOutputLeftPortLocator = locators.MyOutputLeftPortLocator;
window.MyInputRightPortLocator = locators.MyInputRightPortLocator;
window.MyInputTopRightPortLocator = locators.MyInputTopRightPortLocator;

export default locators;