import draw2d from 'draw2d'


/**
 * @class
 * Extended keyboard policy to <b>delete</b> and <b>group</b> figures in the canvas.
 * <br>
 * Keyboard commands
 * <ul>
 *    <li>DEL    - delete selection</li>
 *    <li>Ctrl+G - group/ungroup selection</li>
 *    <li>Ctrl+B - send current selection in the background (toBack)</li>
 *    <li>Ctrl+F - send current selection in the foreground (toFront)</li>
 * </ul>
 *
 * @author Andreas Herz
 * @extends draw2d.policy.canvas.KeyboardPolicy
 */
export default draw2d.policy.canvas.KeyboardPolicy.extend(
    /** @lends draw2d.policy.canvas.ExtendedKeyboardPolicy.prototype */
    {

        NAME: "draw2d.policy.canvas.ExtendedKeyboardPolicy",
        figuresToCopy: [],
        /**
         */
        init: function () {
            this._super()
        },
        recourseGroupCopy: function (canvas, line) {
            let vm = this;
            if (!(line instanceof draw2d.shape.composite.Group)) {
                return []
            }
            let group = [];
            canvas.getFigures().each((i, subline) => {
                if (subline.composite?.id == line.id) {
                    if (subline instanceof draw2d.shape.composite.Group) {
                        group.push({ id: line.id, ids: vm.recourseGroupCopy(canvas, subline), type: 'group' });
                    } else {
                        group.push({ id: subline.id, 'type': 'single' })
                    }
                }
            })
            return group;

        },

        recourseGroupPaste: function (canvas, group) {
            if (group.type != 'group') return null

            let vm = this;
            let figures = new draw2d.util.ArrayList();
            group.ids.forEach(item => {
                if (item.type == 'group') {
                    figures.add(vm.recourseGroupPaste(canvas, item))
                } else {
                    let figure = canvas.getFigure(item.id);
                    if (figure) {
                        let newFigure = figure.clone();
                        canvas.add(newFigure);
                        figures.add(newFigure);
                        newFigure.repaint();
                    }
                }
            })
            canvas.getCommandStack().execute(new draw2d.command.CommandGroup(canvas, figures));
            canvas.getFigure(figures.first().composite.id).toFront();
            return canvas.getFigure(figures.first().composite.id);
        },
        /**
         * 
         * Callback if the user press a key
         *
         * @param {draw2d.Canvas} canvas the related canvas
         * @param {Number} keyCode the pressed key
         * @param {Boolean} shiftKey true if the shift key has been pressed during this event
         * @param {Boolean} ctrlKey true if the ctrl key has been pressed during the event
         * @private
         **/
        onKeyDown: function (canvas, keyCode, shiftKey, ctrlKey) {
            let vm = this;
            if (ctrlKey === true) {
                switch (keyCode) {
                    case 90:
                        canvas.getCommandStack().undo();
                        break;
                    case 89:
                        canvas.getCommandStack().redo();
                        break;
                    case 65:
                        canvas.setCurrentSelection(canvas.getFigures());
                        break;
                    case 86:
                        console.log('asdasd', this.figuresToCopy);
                        this.figuresToCopy.forEach(element => {
                            if (element.type == 'single') {
                                let figure = canvas.getFigure(element.id);
                                if (figure) {
                                    let newFigure = figure.clone();
                                    canvas.add(newFigure);
                                    newFigure.repaint();
                                }
                            }

                            if (element.type == 'group') {
                                let figures = new draw2d.util.ArrayList();
                                element.ids.forEach(item => {
                                    if (item.type == 'group') {
                                        figures.add(vm.recourseGroupPaste(canvas, item))
                                    } else {
                                        let figure = canvas.getFigure(item.id);
                                        if (figure) {
                                            let newFigure = figure.clone();
                                            canvas.add(newFigure);
                                            figures.add(newFigure);
                                            newFigure.repaint();
                                        }
                                    }
                                })
                                canvas.getCommandStack().execute(new draw2d.command.CommandGroup(canvas, figures));
                                canvas.getFigure(figures.first().composite.id).toFront();

                            }

                        });
                        break;
                }

            }
            if (canvas.getPrimarySelection() !== null && ctrlKey === true) {
                console.log(keyCode);
                switch (keyCode) {

                    case 71: // G
                        if (canvas.getPrimarySelection() instanceof draw2d.shape.composite.Group && canvas.getSelection().getSize() === 1) {
                            canvas.getCommandStack().execute(new draw2d.command.CommandUngroup(canvas, canvas.getPrimarySelection()))
                        }
                        else {
                            canvas.getCommandStack().execute(new draw2d.command.CommandGroup(canvas, canvas.getSelection()))
                        }
                        break
                    case 66: // B
                        canvas.getPrimarySelection().toBack()
                        break
                    case 70: // F
                        canvas.getPrimarySelection().toFront();
                        break;
                    case 67: // B
                        console.log('copy');
                        this.figuresToCopy = [];
                        canvas.getSelection().all.each((i, line) => {

                            if (line instanceof draw2d.shape.composite.Group) {
                                let group = vm.recourseGroupCopy(canvas, line);
                                // canvas.getFigures().each((i, subline) => {
                                //     if (subline.composite?.id == line.id) {
                                //         group.push({ id: subline.id })
                                //     }
                                // })
                                this.figuresToCopy.push({ id: line.id, ids: group, type: 'group' })
                                console.log(this.figuresToCopy);

                            } else {
                                this.figuresToCopy.push({ id: line.id, type: 'single' })
                            }
                        });
                        break
                }
            }
            else {
                this._super(canvas, keyCode, shiftKey, ctrlKey)
            }
        }


    })