<template>
    <div class="d-flex flex-wrap align-items-center justify-content-center">

                <div @click="$emit('input', color)" v-for="color, key in colors" :key="key"
                    :style="'background-color:' + color" class="color-picker">
                </div>
                <div class="w-100 p-2">
                    <input type="color" @change="$emit('input', colorPicker)" v-model="colorPicker" class="form-control w-100">
                </div>
       
    </div>
</template>
<script>
export default {
    name: 'ColorPicker',
    props: ['btnClass'],
    data: function () {
        return {
            id: Math.round(Math.random() * 1000),
            colorPicker:'',
            colors: ['#488282', '#52AB90', '#8AC294', '#E6D5A1', '#BA9B8D', '#BA9B8D', '#B3B6E3','#000000','#ff0000','#0000ff','#00ff00','#ffff00'],
        }
    }
}
</script>