<template>
    <div class="loader w-100">
        <div class="loader-bg"></div>
        <div class="loader-loader d-flex justify-content-center w-100 vh-100">
            <SimpleLoader></SimpleLoader>
        </div>
    </div>
</template>
<script>
import SimpleLoader from '../SimpleLoader.vue';

export default {
    name: "BoardLoader",
    components: { SimpleLoader }
}
</script>
<style scoped>
.loader .loader-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;
}

.loader-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1001;
}
</style>