<template>
    <div class="">
        <h5>Komentarze do elementu</h5>
        <div class="d-flex flex-column  " style="height: calc(100vh - 150px);">
            <div ref="commentsScroll" style="overflow-y: auto; overflow-x: hidden;" class=" flex-fill">
                <ul class="list-group list-group-flush">
                    <li v-for="item, i in comments" :key="i"
                        class="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                        <div class="d-flex">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar"><span v-if="!getUserImage(item.user_id)"
                                        class="avatar-initial rounded-circle bg-label-primary">
                                        {{ getUserName(item.user_id).substring(0, 2) }}
                                    </span>
                                    <img :src="getUserImage(item.user_id)" alt="Avatar" class="rounded-circle">
                                </div>
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-1">{{ getUserName(item.user_id) }}</h6>
                                <p class="mb-0 text-wrap">{{ item.comment_body }}</p><small class="text-muted">{{ item.created_at
                                }}</small>
                            </div>
                            <div class="flex-shrink-0 dropdown-notifications-actions"></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class=" p-3">
                <SpeakTextarea v-model="comment" placeholder="Napisz swój komentarz" class="mb-2"></SpeakTextarea>
                <div class="d-flex justify-content-between">
                    <RecordAudio @audio="sendAudio"></RecordAudio>
                    <button @click="addComment" class="btn btn-outline-primary">Dodaj

                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>
<script>
import axios from '../../api/axios';
import RecordAudio from '../RecordAudio.vue';
import SpeakTextarea from '../SpeakTextarea.vue';

export default {
    name: "CommentsModal",
    props: ["figure"],
    mounted: function () {
        this.loadComments();
    },
    data: function () {
        return {
            comments: [],
            comment: "",
        };
    },
    methods: {
        loadComments: function () {
            let vm = this;
            axios.get("api/v1/board/" + this.$route.params.id + "/comment?element_uuid=" + this.figure.id).then(response => {
                vm.comments = response.data.data;
                vm.$nextTick(function () {
                        vm.$refs.commentsScroll.scrollTop = vm.$refs.commentsScroll.scrollHeight;
                    
                })
            });
        },
        sendAudio: function (audio) {
            this.comment = audio;
            this.addComment();
        },
        addComment: function () {
            let data = {
                comment_id: "3",
                comment_body: this.comment,
                board_id: this.$route.params.id,
                element_uuid: this.figure.id,
            };
            axios.post("api/v1/board/" + this.$route.params.id + "/comment", data).then(() => {
                this.loadComments();
                this.$emit("comment");
                this.comment = "";
            });
        },
        getUserImage(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.avatar;
        },
        getUserName(user_id) {
            return this.$store.state.users.find(item => user_id == item.id)?.username;
        },
    },
    components: { SpeakTextarea, RecordAudio }
}
</script>