import draw2d from 'draw2d'
import MyLocators from './MyLocators.js'


export default draw2d.SVGFigure.extend({
    NAME: 'magico.shapes.SVGFigure',
    init: function (attr) {
        this._super(attr);
        if (attr && attr.height > 100) {
            this.createPort("hybrid", new MyLocators.MyInputPortLocator());
            this.createPort("hybrid", new MyLocators.MyOutputPortLocator());
        }
        if (attr && attr.width > 100) {
            this.createPort("hybrid", new MyLocators.MyOutputLeftPortLocator());
            this.createPort("hybrid", new MyLocators.MyInputRightPortLocator());
        }
        if (attr && attr.height <= 100 && attr.width <= 100) {
            this.createPort("hybrid", draw2d.layout.locator.CenterLocator());
        }
    },

    getPersistentAttributes: function () {
        var memento = this._super();
        memento.svg = this.svg;
        return memento;
    },
    setPersistentAttributes: function (memento) {
        this._super(memento);
        this.setSVG(memento.svg)
    }
});