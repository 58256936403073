<template>
    <div>
        <div class="btn-group w-100" :class="drawMode ? 'pencil-active' : ''">
            <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown"
                aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>
            <button data-bs-placement="top" v-tooltip title="Rysuj" @click="drawMode = !drawMode; update()"
                class="btn btn-toolbar w-100" >
                <i class="bi bi-pencil" :style="'color:' + color"></i>
            </button>


            <div class="dropdown-menu " style="min-width: 15rem;" aria-labelledby="dropdownMenuButton1">
                <div class="p-2 d-flex flex-row">
                    <div class="col">

                        <small>Kolor długopisu</small>

                        <ColorPicker @input="setColor">
                            <img class="my-icon" src="@/assets/icons/color-palette.svg">
                        </ColorPicker>


                        <small>Grubość</small>
                        <div class="">

                            <a href="#" @click="setStroke(1)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                <span style="width:100%; border-bottom: 1px solid black; display: block;">
                                </span>
                            </a>
                            <a href="#" @click="setStroke(2)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                <span style="width:100%; border-bottom: 2px solid black; display: block;">
                                </span>
                            </a>
                            <a href="#" @click="setStroke(3)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                <span style="width:100%; border-bottom: 3px solid black; display: block;">
                                </span>
                            </a>
                            <a href="#" @click="setStroke(6)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                <span style="width:100%; border-bottom: 6px solid black; display: block;">
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ColorPicker from './ColorPicker.vue'

export default {
    props: ['value'],
    data: function () {
        return {
            drawMode: false,
            color: '#000000',
            stroke: 1
        }
    },
    watch: {
        value: function () {
            console.log('updated');
            this.drawMode = this.value.drawMode;
            this.color = this.value.color;
            this.stroke = this.value.stroke;
        }
    },
    methods: {
        update: function () {
            this.$emit('input', {
                drawMode: this.drawMode,
                color: this.color,
                stroke: this.stroke,
            })
        },
        setStroke: function (s) {
            this.stroke = s;
            this.update();
        },
        setColor: function (color) {
            this.color = color;
            this.update();
        }
    },
    components: {
        ColorPicker
    }
}
</script>
<style>
.pencil-active {
    border: 2px solid red;
    padding-bottom: 0px!important;
}
.pencil-active  .btn{
    padding-bottom: 4px!important;
}
</style>