<template>
    <div class="noselect" style="position: fixed; top:0; left:0">
        <Toolbar @update="getJson" @add="add" @undo="undo" @redo="redo" :canvas="canvas" :scrollOffset="scrollOffset">
        </Toolbar>
        <ElementToolbar @comment="commentAddSocket" :comments="comments" :recalculate="recalculateEvent" @change="getJson"
            :figure="figureSelected" :canvas="canvas">
        </ElementToolbar>
        <div onselectstart="javascript:/*IE8 hack*/return false" id="draw2d" style="width:10000px; height:10000px;">
        </div>
        <div title="Inny użytkownik" v-for="pointer, key in pointers" :key="key"
            :style="'position:fixed; z-index: 1000000; top:' + parseInt(pointer.y - scrollOffset.y) + 'px; left:' + parseInt(pointer.x - scrollOffset.x) + 'px'">
            <img src="@/assets/cursor.png">
        </div>
        <Transition>
            <BoardLoaderVue v-if="!receivedFirstData"></BoardLoaderVue>
        </Transition>
        <!-- <button style="position: fixed; bottom:30px; right:100px" @click="disconnect">rozlacz</button>
        <button style="position: fixed; bottom:30px; right:200px" @click="connect">polacz</button> -->
    </div>
</template>
  
<script>
import MyReader from '../draw2d/MyReader.js'
import draw2d from 'draw2d'
import ElementToolbar from '../components/board/ElementToolbar.vue';
import Toolbar from '../components/board/Toolbar.vue';

import BoardLoaderVue from '../components/board/BoardLoader.vue'
import ScrollBooster from 'scrollbooster'
import MagicoKeyboardPolicy from '../draw2d/policy/canvas/MagicoKeyboardPolicy';
import '../components/board/custom_components/CustomComponents'
import axios from '../api/axios'
import MyWriter from '../draw2d/MyWriter';
export default {
    name: "Draw2D",
    data: function () {
        return {
            canvas: null,
            conn: null,
            scroller: null,
            lastCommand: null,
            recalculateEvent: 0,
            figureSelected: null,
            receivedFirstData: false,
            pointers: [],
            scrollOffset: { x: 0, y: 0 },
            wheelScrolling: false,
            positonRenewed: false,
            destroyed: false,
            comments: {},
            users: [],
            userActive: true,
            pingInterval: null,
            project: '',
        }
    },
    mounted() {
        this.getProject();
        //  var textFigure = null;
        // var label = null;
        // var line = null;
        // var circle = null;
        this.pingInterval = setInterval(function () {
            if (this.conn) {
                this.conn.send(JSON.stringify({
                    command: 'ping'
                }))
            }
        }.bind(this), 10000)
        window.addEventListener("blur", this.windowBlured);
        window.addEventListener("focus", this.windowFocused);
        document.body.style.overflow = 'hidden';
        this.canvas = new draw2d.Canvas("draw2d");
        this.canvas.installEditPolicy(new draw2d.policy.canvas.CoronaDecorationPolicy());
        this.canvas.installEditPolicy(new draw2d.policy.canvas.SnapToGridEditPolicy());
        this.canvas.installEditPolicy(new draw2d.policy.canvas.WheelZoomPolicy());
        this.canvas.installEditPolicy(new MagicoKeyboardPolicy());
        this.canvas.installEditPolicy(new draw2d.policy.connection.DragConnectionCreatePolicy({
            createConnection: function () {
                var connection = new draw2d.Connection({
                    stroke: 3,
                    outlineStroke: 1,
                    outlineColor: "#303030",
                    color: "91B93E",
                    //            router:new draw2d.layout.connection.ManhattanConnectionRouter()
                    router: new draw2d.layout.connection.SplineConnectionRouter()
                });
                return connection;
            }
        }));
        let vm = this;
        this.canvas.getCommandStack().addEventListener(function (e) {
            if (e.isPostChangeEvent()) {
                // console.log(e);
                vm.recalculateEvent = Date.now();
                console.log('changes', MyWriter.proccessEventCommand(e.command));
                //console.log('ev1', e.command.figure);
                vm.getJson();
            }
        });

        this.scroller = new ScrollBooster({
            viewport: document.body,
            content: document.getElementById('draw2d'),
            emulateScroll: true,
            scrollMode: 'transform',
            shouldScroll: (state, event) => {
                if (!vm.positonRenewed) return true;
                //console.log(event);
                if (event.buttons == 4) {
                    return true
                }
                return false;
            },
            onUpdate: (e) => {
                vm.scrollOffset = e.position;
                if (vm.positonRenewed) {
                    localStorage.setItem('board' + vm.$route.params.id + '.offset', JSON.stringify(e.position));
                }
                if (this.canvas.getCommandStack().isInTransaction()) {
                    this.canvas.getCommandStack().commitTransaction();
                }
                vm.recalculateEvent = Date.now();
            },
        });
        let pos = localStorage.getItem('board' + vm.$route.params.id + '.offset');
        pos = pos ? JSON.parse(pos) : {};
        if (pos && pos.x && pos.y) {
            console.log('[ps]', pos)
            this.scroller.setPosition(pos)
        }
        this.positonRenewed = true;


        document.body.addEventListener('mousemove', this.mouseMoveEvent)
        document.addEventListener("keydown", this.keydownEvent);
        document.addEventListener(
            "wheel",
            this.wheelEvent,
            {
                passive: false
            }
        );


        this.canvas.on("select", this.figureSelectedEvent);
        this.canvas.on("unselect", this.figureUnselectedEvent);
        this.connect();
        window.draw2d = draw2d;
    },
    methods: {

        windowFocused: function () {
            this.userActive = true;
            if (!this.receivedFirstData && this.conn) {
                this.connect();
            }
        },
        windowBlured: function () {
            this.userActive = false
        },
        keydownEvent: function (e) {
            if (
                e.ctrlKey &&
                (e.keyCode == "61" ||
                    e.keyCode == "107" ||
                    e.keyCode == "173" ||
                    e.keyCode == "109" ||
                    e.keyCode == "187" ||
                    e.keyCode == "189")
            ) {
                e.preventDefault();
            }
        },

        getProject: function () {
            let vm = this;
            axios.get('api/v1/project/' + this.$route.params.id + '').then(response => {
                //stworzenie pustych walidacji

                vm.project = response.data;
                vm.$store.commit('setBarTitle', vm.project.project_name)
            });
        },
        wheelEvent: function (e) {
            if (e.ctrlKey) {
                e.preventDefault();

            }
        },
        figureSelectedEvent: function (em, el) {
            this.recalculateEvent = Date.now();
            this.figureSelected = null;
            this.figureSelected = el.figure;
        },
        figureUnselectedEvent: function () {
            this.figureSelected = null;
            this.recalculateEvent = Date.now();
        },

        add: function (el) {
            if (this.canvas.getCommandStack().isInTransaction()) {
                this.canvas.getCommandStack().commitTransaction();
            }

            let xy = document.getElementById('draw2d').style.transform.replace(/[^\d.,]/g, '').split(',');
            let x = document.body.clientWidth / 2 + parseInt(xy[0] ? xy[0] : 0);
            let y = document.body.clientHeight / 2 + parseInt(xy[1] ? xy[1] : 0);
            console.log(x, y);
            this.canvas.getCommandStack().execute(new draw2d.command.CommandAdd(this.canvas, el, x, y))
        },
        undo: function () {
            this.canvas.getCommandStack().undo();
        },
        redo: function () {
            this.canvas.getCommandStack().redo();
        },
        getJson() {

            if (!this.receivedFirstData) return;
            let vm = this;
            var writer = new draw2d.io.json.Writer();
            writer.marshal(this.canvas, function (json) {
                //    console.log('event', JSON.stringify(json, null, 2));
                if (vm.conn) {
                    vm.conn.send(JSON.stringify({
                        command: 'board',
                        message: json,
                        topic: 'board.1'
                    }));
                }
            });
        },
        connect: function () {
            this.destroyed = false;
            if (this.conn || this.conn?.readyState === WebSocket.OPEN) return;
            this.conn = new WebSocket('wss://algorytm.serwer.magico.pl/ws');
            let vm = this;
            this.conn.onopen = function () {
                vm.conn.send(JSON.stringify({
                    command: 'auth',
                    token: localStorage.getItem('algorytm_token'),
                    message: null,
                    topic: 'board.' + parseInt(vm.$route.params.id)
                }));
                vm.$toast("Połączono!", {
                    position: "bottom-left",
                    type: 'success',
                    timeout: 1000
                });
                // vm.conn.send(JSON.stringify({
                //         command: 'board',
                //         message: [],
                //         topic: 'board.1'
                //     }));
            };
            this.conn.onerror = this.onSocketError;
            this.conn.onclose = this.onSocketClose
            this.conn.onmessage = this.onMessage;

        },
        onSocketClose: function () {
            if (this.destroyed) return;
            let vm = this;
            this.conn = null;
            if (!this.userActive) return;
            this.$toast("Rozłoczono! Ponawiam za 3s", {
                position: "bottom-left",
                type: 'error',
                timeout: 3000
            });
            setTimeout(function () {

                vm.connect();
            }, 3000);
        },
        onSocketError: function () {
            let vm = this;
            this.conn = null;
            this.$toast("Rozłoczono! Ponawiam za 3s", {
                position: "bottom-left",
                type: 'error',
                timeout: 3000
            });
            setTimeout(function () {
                vm.connect();
            }, 3000);
        },
        commentAddSocket: function () {
            this.conn.send(JSON.stringify({
                command: 'comments',
                message: null,
                topic: 'board.' + parseInt(this.$route.params.id)
            }));
        },
        onMessage: function (e) {
            var data = JSON.parse(e.data);
            //console.log(data);

            let reader = new MyReader;
            // console.log('here', reader.createFigureFromType('magico.shapes.LabelRectangle'));
            // this.canvas.add(reader.createFigureFromType('magico.shapes.LabelRectangle'));
            if (data.command == 'board') {
                if (!this.receivedFirstData) {
                    this.conn.send(JSON.stringify({
                        command: 'comments',
                        message: null,
                        topic: 'board.' + parseInt(this.$route.params.id)
                    }));
                }
                this.receivedFirstData = false;
                try {
                    this.canvas.clear();
                    reader.unmarshal(this.canvas, data.message);
                } catch (e) {
                    console.error(e);
                }
                this.receivedFirstData = true;
            }
            if (data.command == 'pointers') {
                this.pointers = data.message;
            }

            if (data.command == 'pointers') {
                this.pointers = data.message;
            }
            if (data.command == 'comments') {
                this.comments = data.message;
            }
            if (data.command == 'users') {
                this.users = data.message;
                this.$store.commit('setConnectedUsers', data.message)
            }
            // if (data.message) {
            // }
            // if (data.command === 'auth') {
            // }
        },

        disconnect: function () {
            this.destroyed = true;
            this.conn.close();
            this.conn = null;
        },
        mouseMoveEvent: function (ev) {
            if (this.conn && this.conn.readyState !== WebSocket.CLOSED) {
                this.conn.send(JSON.stringify({
                    command: 'pointer', x: parseInt(ev.clientX) + parseInt(this.scrollOffset.x), y: parseInt(ev.clientY) + parseInt(this.scrollOffset.y)
                }))
            }
        },
    },
    beforeDestroy: function () {
        this.$store.commit('setConnectedUsers', [])
        this.$store.commit('setBarTitle', '')

        window.removeEventListener("blur", this.windowBlured);
        window.removeEventListener("focus", this.windowFocused);
        this.destroyed = true
        document.body.style.overflow = '';
        this.scroller.destroy();
        document.body.removeEventListener('mousemove', this.mouseMoveEvent)
        document.removeEventListener("keydown", this.keydownEvent);
        clearInterval(this.pingInterval)
        document.removeEventListener(
            "wheel",
            this.wheelEvent);
        this.canvas.off("select", this.figureSelectedEvent);
        this.canvas.off("unselect", this.figureUnselectedEvent);
        this.conn.close();
    },
    components: { Toolbar, ElementToolbar, BoardLoaderVue }
}
</script>
<style>
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;


}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>