export default {
    FigureToObject(figure) {
        return figure.getPersistentAttributes();
    },

    proccessEventCommand(command) {
        let result = [];
        let vm = this;
        if (command.commands) {
            command.commands.each(function (i, e) {
                if (e.line)
                    result.push(vm.FigureToObject(e.line));
                if (e.figure)
                    result.push(vm.FigureToObject(e.figure));
                if (e.connection)
                    result.push(vm.connectionToObject(e.connection));
            })
        }
        if (command.figure) {
            result.push(this.FigureToObject(command.figure));
        }
        if (command.line) {
            result.push(this.FigureToObject(command.line));
        }
        if (command.connection) {
            result.push(this.FigureToObject(command.connection));
        }
        return result;
    }
}