<template>
    <div>
        <div class="toolbar shadow-lg">
            <div class="d-flex flex-row align-items-center justify-content-between px-4 w-100">



                <PencilToolbar v-model="pencil"></PencilToolbar>

                <button data-bs-placement="top" v-tooltip title="Kwadrat" @click="addSquare" class="btn btn-toolbar ">
                    <i class="bi bi-square"></i>
                </button>
                <button data-bs-placement="top" v-tooltip title="Koło" @click="addCircle" class="btn btn-toolbar ">
                    <i class="bi bi-circle"></i>
                </button>
                <button data-bs-placement="top" v-tooltip title="Linia" @click="addLine" class="btn btn-toolbar ">
                    <span style="border: 1px solid black; width: 22px; transform: rotate(-60deg);"></span>
                </button>
                <button data-bs-placement="top" v-tooltip title="Romb" @click="addDiamond" class="btn btn-toolbar ">
                    <i class="bi bi-diamond"></i> </button>
                <button data-bs-placement="top" v-tooltip title="Poligon" @click="addPolygon" class="btn btn-toolbar ">
                    <i class="bi bi-triangle"></i> </button>
                <button data-bs-placement="top" v-tooltip title="Obrazek" @click="addImage" class="btn btn-toolbar ">
                    <i class="bi bi-image"></i> </button>

                <button data-bs-placement="top" v-tooltip title="Pinezka" @click="addPin" class="btn btn-toolbar ">
                    <i class="bi bi-pin"></i>
                </button>
                <button data-bs-placement="top" v-tooltip title="Sekcja" @click="addRaftSection" class="btn btn-toolbar ">
                    <i class="bi bi-clipboard2"></i>
                </button>
                <div class="">
                    |
                </div>
                <button data-bs-placement="top" v-tooltip title="Cofnij" @click="$emit('undo')" class="btn btn-toolbar ">
                    <i class="bi bi-arrow-counterclockwise"></i>
                </button>
                <button data-bs-placement="top" v-tooltip title="Ponów" @click="$emit('redo')" class="btn btn-toolbar ">
                    <i class="bi bi-arrow-clockwise"></i>
                </button>
                <Snapshot :canvas="canvas"></Snapshot>

            </div>
        </div>
        <drawPanel @update="$emit('update')" :pencil="pencil" :drawd2dCanvas="canvas" :scrollOffset="scrollOffset"
            v-if="pencil.drawMode"></drawPanel>
    </div>
</template>
<script>
import draw2d from 'draw2d'
import LabelRectangle from './custom_components/LabelRectangle.js'
import LabelCircle from './custom_components/LabelCircle.js'
import LabelDiamond from './custom_components/LabelDiamond.js'
import ImageCpm from './custom_components/Image.js'
import drawPanel from './DrawPanel.vue'
import PencilToolbar from './PencilToolbar.vue'
import Snapshot from './Snapshot.vue'
export default {
    name: "Toolbar",
    props: ['canvas', 'scrollOffset'],
    components: { drawPanel, PencilToolbar, Snapshot },
    data: function () {
        return {
            pencil: {
                drawMode: false,
                color: '#000000',
                stroke: '1'
            }
        }
    },
    methods: {
        addPin: function () {
            this.offDrawMode();
            this.$emit('add', new window.magico.shapes.Pin({ width: 32, height: 32, x: 50, y: 300 }))
        },
        addRaftSection: function () {
            this.offDrawMode();
            this.$emit('add', new window.magico.shapes.RaftSection({ width: 250, height: 250, x: 50, y: 300 }))
        },
        addSquare: function () {
            this.offDrawMode();
            this.$emit('add', new LabelRectangle({ width: 100, height: 100, x: 50, y: 300 }))
        },
        addCircle: function () {
            this.offDrawMode();
            this.$emit('add', new LabelCircle({ width: 100, height: 100, x: 50, y: 300 }))
        },
        addDiamond: function () {
            this.offDrawMode();
            this.$emit('add', new LabelDiamond({ width: 100, height: 100, x: 50, y: 300 }))
        },
        addLine: function () {
            this.offDrawMode();
            let xy = document.getElementById('draw2d').style.transform.replace(/[^\d.,]/g, '').split(',');
            let x = document.body.clientWidth / 2 + parseInt(xy[0] ? xy[0] : 0);
            let y = document.body.clientHeight / 2 + parseInt(xy[1] ? xy[1] : 0);

            this.$emit('add', new draw2d.shape.basic.PolyLine({ startX: x, startY: y, endX: x + 100, endY: y + 100, stroke: 2 }))
        },
        addPolygon: function () {
            this.offDrawMode();
            this.$emit('add', new draw2d.shape.basic.Polygon({ width: 100, height: 100 }))
        },
        addImage: function () {
            this.offDrawMode();
            let vm = this;
            this.$filechooser.open({
                chooseCallback: function (file) {
                    console.log(file);
                    vm.getImageInfo(file.publicUrl).then((data => {

                        vm.$emit('add', new ImageCpm({ path: file.publicUrl, width: data.width, height: data.height }))
                    }))
                }
            });


        },
        offDrawMode: function () {
            this.pencil = {
                drawMode: false,
                color: this.pencil.color,
                stroke: this.pencil.stroke
            }
        },
        getImageInfo: function (url) {
            return new Promise((success) => {
                // console.log('img loaded');
                let img = new Image();
                img.onload = function () {
                    let data = {}
                    data.height = img.height;
                    data.width = img.width;
                    success(data)
                }
                img.src = url;
                // console.log(url);
            })
        },
       
        addText: function () {
        }
    },
    mounted() {
    }
}
</script>
<style>
.toolbar {
    position: fixed;
    bottom: 15px;
    height: 60px;
    right: 25%;
    width: 50%;
    background-color: whitesmoke;
    border: 1px rgb(194, 194, 194) solid;
    z-index: 1000;
    border-radius: 10px;
}

.btn-toolbar {
    transition: all ease 0.5s;
    position: relative;
    padding: 12px;
    width: 50px;
    display: flex;
    min-height: 50px;
    justify-content: center;
    font-size: 20px;
    color: black;
    bottom: 0;
}

.btn-toolbar:hover {
    bottom: 20px;
    transform: scale(1.5);
    background-color: rgb(212, 212, 212);
    color: black;
    box-shadow: 0px 8px 13px -7px rgba(66, 68, 90, 1);
}

.tooltip {
    z-index: 10000000;
}

@media only screen and (min-width: 1920px) {}
</style>