<template>
    <div class="d-inline-block">
        <div class="btn-group" role="group">
            <button class="btn btn-outline-secondary p-0 px-2" @click="startRecording"><i class=" fs-4  bi bi-mic"></i>
                <span v-if="isRecording || recorded"> {{ timer }}s</span></button>
            <button v-if="recorded" class="btn btn-outline-secondary p-2" @click="$refs.audioElement.play()"><i
                    class="  bi bi-play"></i></button>
            <button v-if="recorded" class="btn btn-outline-secondary p-2" @click="send"><i
                    class="  bi bi-send"></i></button>
        </div>

        <audio ref="audioElement" :src="audioUrl"></audio>
    </div>
</template>
  
<script>
import axios from '../api/axios'
export default {
    data() {
        return {
            isRecording: false,
            audioChunks: [],
            audioUrl: null,
            mediaRecorder: null,
            timer: 0,
            intervalId: 0,
            blob: null,
            recorded: false,
        };
    },
    onDestroy: function () {
        if (this.isRecording) {
            this.mediaRecorder.stop();
        }
        clearInterval(this.intervalId)
    },
    methods: {
        send: function () {
            const formData = new FormData();
            let name = Date.now() + '.webm';
            formData.append("file", this.blob, name);
            formData.append('path', 'files/audio');
            formData.append('adapter', 'local')
            let vm = this;
            axios.post('api/v3/filemanager/upload', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            }).then(() => {
                vm.blob = null;
                vm.audioChunks = [];
                vm.recorded = false;
                vm.$emit('audio', axios.defaults.baseURL + '/files/audio/' + name);
            })
        },
        startRecording() {

            if (this.isRecording) {
                this.mediaRecorder.stop();
                this.isRecording = false;
                clearInterval(this.intervalId);
                return;
            }
            this.recorded = false;
            this.timer = 0;
            this.isRecording = true;
            this.intervalId = setInterval(function () {
                this.timer++;
                if (this.timer == 10) {
                    this.startRecording();
                }
            }.bind(this), 1000);
            // Tworzenie obiektu MediaRecorder
            navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                this.mediaRecorder = new MediaRecorder(stream);
                this.audioChunks = [];
                // Dodawanie słuchacza dla zdarzenia ondataavailable
                this.mediaRecorder.addEventListener("dataavailable", (event) => {
                    this.audioChunks.push(event.data);
                });

                // Dodawanie słuchacza dla zdarzenia stop
                this.mediaRecorder.addEventListener("stop", () => {
                    const blob = new Blob(this.audioChunks, { type: "audio/webm" });
                    const url = URL.createObjectURL(blob);
                    this.blob = blob;
                    this.audioUrl = url;
                    this.isRecording = false;
                    this.recorded = true;
                    this.$refs.audioElement.play();
                    // Wysyłanie pliku audio na serwer


                });

                // Rozpoczynanie nagrywania
                this.mediaRecorder.start();
            });
        },
    },
};
</script>
  