/**
 * @class example.connection_labeledit.LabelConnection
 * 
 * A simple Connection with a label wehich sticks in the middle of the connection..
 *
 * @author Andreas Herz
 * @extend draw2d.Connection
 */
import draw2d from 'draw2d'
import MyLocators from './MyLocators.js'
import MyLabelInplaceEditor from '../../../draw2d/ui/MyLabelInplaceEditor';

var LabelRectangle = draw2d.shape.basic.Rectangle.extend({
  NAME: "magico.shapes.LabelRectangle",
  init: function (attr) {
    this._super(attr);

    // Create any Draw2D figure as decoration for the connection
    //
    this.label = new draw2d.shape.basic.Label({ text: "Tekst", color: "#0d0d0d", fontColor: "#0d0d0d", stroke: 0, fontSize: 16 });
    this.label.setUserData({ elemId: "label1" });
    // add the new decoration to the connection with a position locator.
    //
    this.add(this.label, new draw2d.layout.locator.CenterLocator(this));
    this.createPort("hybrid", new MyLocators.MyInputPortLocator());
    this.createPort("hybrid", new MyLocators.MyOutputPortLocator());
    this.createPort("hybrid", new MyLocators.MyOutputLeftPortLocator());
    this.createPort("hybrid", new MyLocators.MyInputRightPortLocator());

    this.label.installEditor(new MyLabelInplaceEditor());
  },
  getPersistentAttributes: function () {
    var memento = this._super();
    memento.chld = [];
    this.children.each(function (i, e) {
      memento.chld.push({ figure: e.figure.getPersistentAttributes(), locator: e.locator.NAME });
    })
    return memento;
  },
  repaint: function (attr) {
    this.label?.clearCache();
    this.label?.repaint();
    this._super(attr);
  },
  setPersistentAttributes: function (memento) {
    this._super(memento);
    if (memento.chld && memento.chld.length > 0) {
      memento.chld.forEach(item => {

        var oldItem = null;
        this.children.each(function (i, e) {
          if (e.figure.userData.elemId == item.figure.userData.elemId) {
            oldItem = e.figure;
          }
        })
        if (!oldItem) {
          var figure = eval("new " + item.figure.type + "()");
          figure.setPersistentAttributes(item.figure);
          var locator = eval("new " + item.locator + "()");
          this.add(figure, locator);
        } else {
          oldItem.setPersistentAttributes(item.figure);
        }
      })
    }
  }
});

export default LabelRectangle;