<template>
    <div class="signature_fixed">
        <canvas id="signatureCanvas" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
</template>
<script>
import C2S from 'canvas2svg'
import SVGFigure from './custom_components/SVGFigure'
export default {
    name: 'DrawPanel',
    props: {
        custom: Boolean,
        drawd2dCanvas: Object,
        scrollOffset: Object,
        pencil: Object
    },
    data: function () {
        return {
            code: '',
            listener: null,
            scanning: false,
            context: null,
            isDrawing: false,
            canvas: null,
            canvasWidth: window.screen.width - 10,
            canvasHeight: window.screen.height - 10,
            emptyCanvas: true,
            lineDim: {
                sX: 0, sY: 0, eX: 0, eY: 0
            },
            points: [],
        }
    },
    computed: {

    },
    watch: {},
    methods: {

        makeBodyNoscalable: function () {
            var html = document.querySelector("html");
            html.classList.add('drawing');
        },
        fillLineDim: function (coors) {
            if (coors.x < this.lineDim.sX) {
                this.lineDim.sX = coors.x;
            }
            if (coors.y < this.lineDim.sY) {
                this.lineDim.sY = coors.y;
            }
            if (coors.x > this.lineDim.eX) {
                this.lineDim.eX = coors.x;
            }
            if (coors.y > this.lineDim.eY) {
                this.lineDim.eY = coors.y;
            }
        },
        makeBodyNormal: function () {
            var html = document.querySelector("html");
            html.classList.remove('drawing');
        },
        touchstart: function (coors) {
            this.points = [];
            this.lineDim = { sX: coors.x, sY: coors.y, eX: coors.x, eY: coors.y };
            //console.log(this);
            this.context.beginPath();
            this.context.moveTo(coors.x, coors.y);
            this.context.strokeStyle = this.pencil.color;
            this.context.lineWidth = this.pencil.stroke;
            this.isDrawing = true;
        },
        hideAllPorts: function () {
            this.drawd2dCanvas.getFigures().each((i, figure) => {
                figure.getPorts().each((i, p) => {
                    p.setAlpha(0.0)
                })
            });
        },
        touchmove: function (coors) {
            if (this.isDrawing) {
                this.fillLineDim(coors);
                this.points.push([coors.x, coors.y]);
                this.context.lineTo(coors.x, coors.y);
                this.context.stroke();
                this.emptyCanvas = false;
            }
        },
        touchend: function (coors) {
            if (this.isDrawing) {
                //  this.context.endPath();
                //this.context.beginPath();
                //  this.context.rect(this.lineDim.sX, this.lineDim.sY, this.lineDim.eX - this.lineDim.sX, this.lineDim.eY - this.lineDim.sY);
                //this.context.stroke();

                this.touchmove(coors);
                this.isDrawing = false;
                this.exportSvg();
                this.clearCanvas();
            }
        },

        exportSvg: function () {
            let width = this.lineDim.eX - this.lineDim.sX;
            let height = this.lineDim.eY - this.lineDim.sY;
            var ctx2 = new C2S(width, height); //width, height of your desired svg file
            ctx2.beginPath();
            ctx2.strokeStyle = this.pencil.color;
            ctx2.lineWidth = this.pencil.stroke;
            // ctx2.moveTo(this.points[0][0], this.points[0][1]);
            this.points.forEach(point => {
                ctx2.lineTo(point[0] - this.lineDim.sX, point[1] - this.lineDim.sY);
                ctx2.stroke();
            })
            var myRectangle = ctx2.getSerializedSvg(true); //true here will replace any named entities with numbered ones.

            console.log(myRectangle);
            this.addSVGFigure(myRectangle, Math.round(this.scrollOffset.x) + this.lineDim.sX, Math.round(this.scrollOffset.y) + this.lineDim.sY, width, height);
            return myRectangle;

        },
        addSVGFigure: function (svg, x, y, w, h) {
            svg = svg.replace(/<g>/g, '')
            svg = svg.replace(/<\\g>/g, '')
            console.log('newsvg', svg);
            let figure = new SVGFigure({ svg: svg, x: x, y: y, width: w, height: h });
            this.drawd2dCanvas.add(figure);
            this.hideAllPorts();
            // console.log('nodes', figure.createSet());
            this.$emit('update');
        },
        clearCanvas: function () {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.emptyCanvas = true;
        },
        preventEvent: function (e) {
            e.preventDefault();
        },
        removeEvents: function () {
            if (this.canvas) {
                this.canvas.removeEventListener('touchstart', this.draw, false);
                this.canvas.removeEventListener('touchmove', this.draw, false);
                this.canvas.removeEventListener('touchend', this.draw, false);
                this.canvas.removeEventListener('mousedown', this.draw, false);
                this.canvas.removeEventListener('mousemove', this.draw, false);
                this.canvas.removeEventListener('mouseup', this.draw, false);
            }
            document.body.removeEventListener('touchmove', this.preventEvent)
            document.body.removeEventListener('drop', this.preventEvent)
            document.body.removeEventListener('dragenter', this.preventEvent)
            window.removeEventListener('touchmove', this.preventEvent)
            window.removeEventListener('drop', this.preventEvent)
            window.removeEventListener('dragenter', this.preventEvent)
        },
        openModal: function () {
            this.emptyCanvas = true;

            //  screen.lockOrientation("portrait-primary");
            if (window.screen)
                this.removeEvents();
            //this.makeBodyNoscalable();
            this.scanning = true;
            this.canvas = document.getElementById('signatureCanvas');
            this.context = this.canvas.getContext('2d');


            // this.globalAlpha = 0.0;
            // this.fillStyle = "yellow";
            // this.fillRect(0, 0, 10000, 10000);

            // create a this.drawer which tracks touch movements

            // create a function to pass touch events and coordinates to this.drawer


            // detect touch capabilities
            var touchAvailable = ('createTouch' in document) || ('ontouchstart' in window);

            // attach the touchstart, touchmove, touchend event listeners.
            if (touchAvailable) {
                console.log('tousch')
                this.canvas.addEventListener('touchstart', this.draw, false);
                this.canvas.addEventListener('touchmove', this.draw, false);
                this.canvas.addEventListener('touchend', this.draw, false);
            }
            // attach the mousedown, mousemove, mouseup event listeners.
            else {
                console.log('mouse')
                this.canvas.addEventListener('mousedown', this.draw, false);
                this.canvas.addEventListener('mousemove', this.draw, false);
                this.canvas.addEventListener('mouseup', this.draw, false);
            }

            // prevent elastic scrolling
            document.body.addEventListener('touchmove', this.preventEvent)
            document.body.addEventListener('drop', this.preventEvent)
            document.body.addEventListener('dragenter', this.preventEvent)
            window.addEventListener('touchmove', this.preventEvent)
            window.addEventListener('drop', this.preventEvent)
            window.addEventListener('dragenter', this.preventEvent)

        },
        closeModal: function () {
            //this.closeFullscreen();
            this.clearCanvas();
            this.removeEvents();
            this.scanning = false;
            //this.makeBodyNormal();
        },
        draw: function (event) {
            var type = null;
            // map mouse events to touch events
            switch (event.type) {
                case "mousedown":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchstart";
                    break;
                case "mousemove":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchmove";
                    break;
                case "mouseup":
                    event.touches = [];
                    event.touches[0] = {
                        pageX: event.pageX,
                        pageY: event.pageY
                    };
                    type = "touchend";
                    break;
            }

            // touchend clear the touches[0], so we need to use changedTouches[0]
            var coors;
            if (event.type === "touchend") {
                coors = {
                    x: event.changedTouches[0].pageX,
                    y: event.changedTouches[0].pageY
                };
            } else {
                // get the touch coordinates
                coors = {
                    x: event.touches[0].pageX,
                    y: event.touches[0].pageY
                };
            }
            type = type || event.type
            // pass the coordinates to the appropriate handler
            this[type](coors);
        },
        save: function () {
            var myImage = new Image();
            myImage.src = this.canvas.toDataURL();
            let vm = this;
            myImage.onload = function () {
                if (vm.canvasHeight > vm.canvasWidth) {
                    // reset the canvas with new dimensions
                    vm.canvas.width = vm.canvasHeight;
                    vm.canvas.height = vm.canvasWidth;
                    vm.canvasWidth = vm.canvas.width;
                    vm.canvasHeight = vm.canvas.height;

                    vm.context.save();
                    // translate and rotate
                    vm.context.translate(vm.canvasWidth, vm.canvasWidth / vm.canvasWidth);
                    vm.context.rotate(Math.PI / 2);

                    // draw the previows image, now rotated
                    vm.context.drawImage(myImage, 0, 0);
                    vm.context.restore();

                }

                // clear the temporary image
                myImage = null;

                var data = vm.canvas.toDataURL('image/png', 0.8);
                vm.$emit('input', data);
                vm.$emit('change', data);
                vm.closeModal();
            }

        }
    },
    mounted() {
        let vm = this;
        window.onresize = function () {
            vm.canvasWidth = window.screen.width - 20;
            vm.canvasHeight = window.screen.height - 20;
        }
        this.openModal();

    },
    beforeDestroy: function () {
        this.makeBodyNormal();
        this.closeModal();
    },

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
::before,
::after {
    pointer-events: none;
}

.signature_fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: transparent;
}

.square {
    position: fixed;
    bottom: calc(50% - 150px);
    right: calc(50% - 150px);
    border: 6px solid red;
    height: 300px;
    width: 300px;
}

#signatureCanvas {
    z-index: 10001;
    background-color: transparent;
}

.scanbuttons {
    position: fixed;
    z-index: 120;
    bottom: 0;
}

.drawing {
    overflow: hidden;
    height: 100%;

}

.makeSignatureInfo {
    position: fixed;
    right: -80px;
    bottom: 50%;
    z-index: 2000;
    font-size: 42px;
    transform: rotate(-90deg);
}

@media (orientation: landscape) {
    .makeSignatureInfo {
        position: fixed;
        right: 0;
        left: 0;
        width: 100%;
        text-align: center;
        bottom: 120px;
        z-index: 2000;
        font-size: 42px;
        transform: rotate(0deg);
    }
}

.drawing body {
    position: fixed;
    overflow: hidden;
    height: 100%;
}
</style>