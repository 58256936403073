<template>
    <button @click="makeSnapshot" v-tooltip title="Wykonaj migawkę" class="btn btn-toolbar">
        <i v-if="!loading" class="bi bi-camera"></i> <span v-else class="spinner-border spinner-border-sm" role="status"
            aria-hidden="true"></span>
    </button>
</template>
<script>
import draw2d from 'draw2d';
import dayjs from 'dayjs';
import BlobWriter from '../../draw2d/io/png/BlobWriter'
import axios from '../../api/axios'
export default {
    name: 'Snapshot',
    props: ['canvas', 'board'],
    data: function () {
        return {
            loading: false,
        }
    },
    methods: {
        makeSnapshot: function () {
            if (this.loading) return;
            this.loading = true;
            let vm = this;
            var xCoords = [];
            var yCoords = [];
            this.canvas.getFigures().each(function (i, f) {
                var b = f.getBoundingBox();
                xCoords.push(b.x, b.x + b.w);
                yCoords.push(b.y, b.y + b.h);
            });
            var minX = Math.min.apply(Math, xCoords) - 25;
            var minY = Math.min.apply(Math, yCoords) - 25;
            var width = Math.max.apply(Math, xCoords) - minX + 25;
            var height = Math.max.apply(Math, yCoords) - minY + 25;
            try {
                var writer = new BlobWriter();
                writer.marshal(this.canvas, function (png) {
                    vm.upload(png)
                }, new draw2d.geo.Rectangle(minX, minY, width, height));
            } catch (e) {
                vm.loading = false;
            }
        },


        upload: function (blob) {
            let vm = this;
            const formData = new FormData();
            let name = 'snapshot_' + dayjs().format('YYYY_MM_DD_HH_mm_ss') + '.png';
            formData.append("file", blob, name);
            formData.append('path', 'files/project/' + this.$route.params.id + '/board_snapshot');
            formData.append('adapter', 'local')
            axios.post('api/v3/filemanager/upload', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            }).then(() => {
                vm.loading = false;
                vm.recorded = false;
                vm.$toast('Zapisano migawkę w plikach projektu')
            }, () => {
                vm.$toast('Błąd zapisu migawki')
            })
        }

    }
}
</script>