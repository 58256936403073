<template>
    <div class="toolbar-element" v-if="figure" :style="'top:' + posY + 'px ; left:' + posX + 'px'">
        <div ref="toolbar" class="dropdown">

            <div class="d-inline-block btn-group">

                <button v-if="!canUngroup && hasDecorations" v-tooltip title="Kolory obiektu" class="btn" type="button"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img class="my-icon" src="@/assets/icons/color-palette.svg">
                </button>
                <div class="dropdown-menu " style="min-width: 25rem;" aria-labelledby="dropdownMenuButton1">
                    <div class="p-2 d-flex flex-row">
                        <div class="col">

                            <small>Wypełnienie</small>

                            <ColorPicker @input="changeColor">
                                <img class="my-icon" src="@/assets/icons/color-palette.svg">
                            </ColorPicker>
                            <button @click="toogleFill" class="btn btn-outline-secondary">
                                <img class="my-icon" src="@/assets/icons/fill-color.svg">
                            </button>
                        </div>
                        <div class="col">
                            <small>Obramowanie</small>
                            <div class="">
                                <a href="#" @click="setStroke(0)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                    <span style="width:100%; border-bottom: 0px solid black; display: block;">
                                    </span>
                                </a>
                                <a href="#" @click="setStroke(1)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                    <span style="width:100%; border-bottom: 1px solid black; display: block;">
                                    </span>
                                </a>
                                <a href="#" @click="setStroke(2)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                    <span style="width:100%; border-bottom: 2px solid black; display: block;">
                                    </span>
                                </a>
                                <a href="#" @click="setStroke(3)" class="btn btn-outline-secondary w-100 py-2 mb-2">
                                    <span style="width:100%; border-bottom: 3px solid black; display: block;">
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-inline-block btn-group">

                <button v-if="!canUngroup && hasLabel" v-tooltip title="Ustawienia tekstu" class="btn" type="button"
                    id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                    <img class="my-icon" src="@/assets/icons/font.svg">
                </button>
                <ul class="dropdown-menu" style="min-width: 15rem;" aria-labelledby="dropdownMenuButton2">
                    <li class="d-flex  flex-wrap justify-content-center align-items-center gap-1">
                        <div class="p-1">
                            <select @change="setFontSize" v-model="fontSize" class="form-control">
                                <option value="12">12px</option>
                                <option value="16">16px</option>
                                <option value="24">24px</option>
                                <option value="32">32px</option>
                                <option value="48">48px</option>
                                <option value="64">64px</option>
                            </select>
                        </div>
                        <button @click="toogleBold" v-tooltip title="Pogrubienie" class="btn btn-outline-secondary"
                            type="button">
                            <img class="my-icon" src="@/assets/icons/bold.svg">
                        </button>
                        <ColorPicker @input="changeLabelColor" class="p-2">
                        </ColorPicker>

                    </li>

                </ul>
            </div>
            <button v-if="manySelected" @click="group" class="btn" v-tooltip title="Grupuj elementy" type="button">
                <img class="my-icon" src="@/assets/icons/group.svg">

            </button>
            <button @click="ungroup" v-if="canUngroup" v-tooltip title="Rozgrupuj elementy" class="btn" type="button">
                <img class="my-icon" src="@/assets/icons/ungroup.svg">
            </button>
            <button @click="toFront" v-tooltip title="Przenieś na góre" class="btn" type="button">
                <img class="my-icon" src="@/assets/icons/bring-to-front.svg">
            </button>
            <button @click="showComments = !showComments" v-tooltip title="Komentarze" class="btn" type="button">
                <img class="my-icon" src="@/assets/icons/comment.svg">
                <span v-if="comments[figure?.id]" class="badge bg-primary rounded-circle"
                    style="font-size:12px; position: absolute; top:-3px; right:8px">{{ comments[figure?.id] }}</span>
            </button>

        </div>
        <MagicoOffcanvas title="Szczegóły elementu" v-model="showComments">
            <CommentsModal @comment="$emit('comment')" v-if="showComments" :figure="figure"></CommentsModal>
        </MagicoOffcanvas>
        <i @mousedown="rotateClick" style="position: absolute; bottom:-20x; font-size: 20px;left:-35px; cursor: sw-resize;"
            class="bi bi-arrow-repeat"></i>

    </div>
</template>
<script>
import draw2d from 'draw2d'
import ColorPicker from './ColorPicker.vue';
import CommentsModal from './CommentsModal.vue';
import MagicoOffcanvas from '../../components/MagicoOffcanvas.vue'
export default {
    name: "",
    props: ["figure", "recalculate", "canvas", "comments"],
    data: function () {
        return {
            colors: ["#488282", "#52AB90", "#8AC294", "#E6D5A1", "#BA9B8D", "#BA9B8D", "#B3B6E3"],
            posX: 0,
            posY: 0,
            manySelected: false,
            canUngroup: false,
            fontSize: 16,
            showComments: false,
            rotating: false,
        };
    },

    watch: {

        figure: function () {
            this.calculatePos();
            this.fontSize = this.figure?.label ? this.figure.label.fontSize : 12;
            console.log(this.figure)
            // if(this.figure){
            //     this.canvas.getFigures().each((i, line) => {
            //         console.log(line.composite?.id,line.id,this.figure.id)
            //     })
            // }
            if (!this.figure) {
                this.showComments = false;
            }
        },
        recalculate: function () {
            this.manySelected = false;
            this.canUngroup = false;
            if (this.canvas.getSelection().all && this.canvas.getSelection().all.data.length > 1) {
                this.manySelected = true;
            }
            if (this.figure && this.figure.NAME == "draw2d.shape.composite.Group") {
                this.canUngroup = true;
            }
            this.calculatePos();
        },
    },
    computed: {
        hasLabel: function () {
            if (this.manySelected) return false;
            return this.figure.NAME != 'magico.shapes.Image'
        },
        hasDecorations: function () {
            if (this.manySelected) return false;
            return this.figure.NAME != 'magico.shapes.Image'
        }
    },
    mounted() {
        document.addEventListener("scroll", this.scroll);
    },
    methods: {

        rotateClick: function () {
            console.log('clikc');
            document.body.addEventListener('mousemove', this.rotateMove);
            document.body.addEventListener('mouseup', this.rotateMoveEnd);

        },
        rotateMoveEnd: function () {
            document.body.removeEventListener('mousemove', this.rotateMove);
            document.body.removeEventListener('mouseup', this.rotateMoveEnd);
            this.figure.clearCache();
            this.figure.repaint()


        },
        rotateMove: function (e) {
            // console.log('move', e);
            this.figure?.setRotationAngle(this.getDragAngle(e));
            this.figure.repaint()
        },

        getDragAngle: function (event) {
            var startAngle = parseFloat(this.figure.angle) || 0;
            var center = {
                x: parseFloat(this.figure.x) || 0,
                y: parseFloat(this.figure.y) || 0
            };
            var angle = Math.atan2(center.y - event.clientY,
                center.x - event.clientX) * 180 / Math.PI;

            return angle - startAngle;
        },
        calculatePos: function () {
            // console.log(this.canvas)
            if (this.figure) {
                let xy = document.getElementById("draw2d").style.transform.replace(/[^\d.,]/g, "").split(",");
                this.posX = parseInt(this.figure.x) * (1 / this.canvas.zoomFactor) + parseInt(xy[0] ? (xy[0] * -1) : 0);
                this.posY = (parseInt(this.figure.y) * (1 / this.canvas.zoomFactor) - 50) + parseInt(xy[1] ? (xy[1] * -1) - 25 : 0);
            }
        },
        group: function () {
            this.canvas.getCommandStack().execute(new draw2d.command.CommandGroup(this.canvas, this.canvas.getSelection()));
        },
        ungroup: function () {
            this.canvas.getCommandStack().execute(new draw2d.command.CommandUngroup(this.canvas, this.canvas.getSelection()));
        },
        changeColor: function (color) {
            console.log(color);
            this.figure.setUserData({ lastBg: color });
            this.figure.setBackgroundColor(color);
            this.$emit("change");
        },
        changeLabelColor: function (color) {
            console.log(color);
            this.figure.label?.setFontColor(color);
            this.$emit("change");
        },
        scroll: function () {
            this.calculatePos();
        },


        toogleFill: function () {
            let color = this.figure.getBackgroundColor();
            console.log(color);
            if (color && color.hashString != "none") {
                this.figure.setUserData({ lastBg: color.hex() });
                this.figure.setBackgroundColor(null);
            } else {
                let lastColor = this.figure.userData.lastBg;
                console.log('lastColor', lastColor);
                this.figure.setBackgroundColor(lastColor);
                this.figure.setUserData({ lastBg: null });
            }
            this.$emit("change");

        },
        toogleBold: function () {
            this.figure.label?.setBold(!this.figure.label?.bold);
            this.$emit("change");
        },
        toFront: function () {
            this.figure.toFront();
            this.$emit("change");
        },
        setFontSize: function () {
            if (this.figure.label) {
                this.figure.label.setFontSize(this.fontSize);
                this.$emit("change");
            }
        },
        setStroke: function (stroke) {
            this.figure.setStroke(stroke);
            this.$emit("change");
        }
    },
    onDestroy() {
        document.removeEventListener(this.scroll);
    },
    components: { ColorPicker, CommentsModal, MagicoOffcanvas }
}
</script>
<style>
.toolbar-element {
    position: fixed;
    z-index: 10000;
    border: 1px solid black;
    background-color: rgb(204, 204, 204);
    border-radius: 3px;
    padding: 3px;
}

.color-picker {
    width: 30px;
    height: 30px;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.tooltip {
    z-index: 100001;
}

.my-icon {
    width: 20px;
    height: 20px;
}
</style>