/**
 * @class example.connection_labeledit.LabelConnection
 * 
 * A simple Connection with a label wehich sticks in the middle of the connection..
 *
 * @author Andreas Herz
 * @extend draw2d.Connection
 */
import draw2d from 'draw2d'
import MyLocators from './MyLocators.js'

var LabelRectangle = draw2d.shape.basic.Image.extend({
    NAME: "magico.shapes.Image",
    init: function (attr) {
        this._super(attr);

        this.createPort("hybrid", new MyLocators.MyInputPortLocator());
        this.createPort("hybrid", new MyLocators.MyOutputPortLocator());
        this.createPort("hybrid", new MyLocators.MyOutputLeftPortLocator());
        this.createPort("hybrid", new MyLocators.MyInputRightPortLocator());
    },
    createShapeElement: function () {
        let el = this.canvas.paper.image(this.path, this.getX(), this.getY(), this.getWidth(), this.getHeight());

        el.rotate(this.getRotationAngle());

        this.lastAngle = this.getRotationAngle()
        return el;
    }, repaint: function (attributes) {
        if (this.toRotate) {
            this.shape?.rotate((this.getRotationAngle() - this.lastAngle));
            this.toRotate = false;
        }
        this._super(attributes);
        //        console.log(this);
        return this;
    },
    setRotationAngle: function (attr) {
        this.lastAngle = this.getRotationAngle();
        this.toRotate = true;
        this._super(attr)
    },
    applyTransformation: function () {
        let ts = "R" + this.rotationAngle

        // if (this.getRotationAngle() === 90 || this.getRotationAngle() === 270) {
        //     let ratio = this.getHeight() / this.getWidth()
        //     ts = ts + "S" + ratio + "," + 1 / ratio + "," + (this.getAbsoluteX() + this.getWidth() / 2) + "," + (this.getAbsoluteY() + this.getHeight() / 2)
        // }

        this.shape.transform(ts)

        return this
    },

});

export default LabelRectangle;