<template>
    <div style="position: relative;">
        <a v-if="speachAvailable" @click.prevent="startListening" title="Podyktuj"
            style="position: absolute; top:5px;right:8px; background-color: white;" href="">

            <svg height="16pt" viewBox="-90 1 511 511.99899" width="16pt" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m332.464844 275.082031c0-8.429687-6.835938-15.265625-15.269532-15.265625-8.433593 0-15.269531 6.835938-15.269531 15.265625 0 74.6875-60.757812 135.445313-135.445312 135.445313-74.683594 0-135.441407-60.757813-135.441407-135.445313 0-8.429687-6.835937-15.265625-15.269531-15.265625-8.433593 0-15.269531 6.835938-15.269531 15.265625 0 86.378907 66.320312 157.539063 150.710938 165.273438v41.105469h-56.664063c-8.433594 0-15.269531 6.835937-15.269531 15.269531 0 8.433593 6.835937 15.269531 15.269531 15.269531h143.871094c8.429687 0 15.265625-6.835938 15.265625-15.269531 0-8.433594-6.835938-15.269531-15.265625-15.269531h-56.667969v-41.105469c84.394531-7.730469 150.714844-78.894531 150.714844-165.273438zm0 0" />
                <path
                    d="m166.480469 372.851562c53.910156 0 97.769531-43.859374 97.769531-97.769531v-177.316406c0-53.90625-43.859375-97.765625-97.769531-97.765625-53.90625 0-97.765625 43.859375-97.765625 97.765625v177.316406c0 53.910157 43.859375 97.769531 97.765625 97.769531zm-67.230469-275.085937c0-37.070313 30.160156-67.226563 67.230469-67.226563 37.070312 0 67.230469 30.15625 67.230469 67.226563v177.316406c0 37.070313-30.160157 67.230469-67.230469 67.230469-37.070313 0-67.230469-30.160156-67.230469-67.230469zm0 0" />
            </svg>
            <div v-if="isListening" style="position: absolute; top:-5px;right:-5px" class="spinner-grow text-success"
                role="status">
            </div>
        </a>
        <textarea :placeholder="placeholder" @input="$emit('input', transcription)" v-model="transcription"
            class="form-control"></textarea>
    </div>
</template>
<script>
export default {
    name: 'SpeakTextarea',
    props: ['placeholder', 'value'],
    data: function () {
        return {
            transcription: '',
            recognition: null,
            isListening: false,
            speachAvailable: true,
        }
    },
    watch: {
        value: function () {
            this.transcription = this.value;
            if (this.value == '' && this.isListening) {
                this.recognition.stop();
                this.isListening = false;
                this.transcription = '';
            }
        }
    },
    mounted() {
        if ('webkitSpeechRecognition' in window) {
            // Tworzenie obiektu rozpoznawania mowy
            this.recognition = new window.webkitSpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.lang = 'pl-PL';
            // Dodawanie słuchacza dla zdarzenia rozpoznania
            this.recognition.addEventListener("result", (event) => {
                const transcript = Array.from(event.results)
                    .map((result) => result[0].transcript)
                    .join("");

                // Aktualizacja transkrypcji
                this.transcription = transcript;
                this.$emit('input', this.transcription);
            });

            // Dodawanie słuchacza dla zdarzenia końca rozpoznawania
            this.recognition.addEventListener("end", () => {
                this.isListening = false;
            });
        } else {
            this.speachAvailable = false;
        }
    },
    methods: {
        startListening() {
            // Sprawdzanie, czy rozpoznawanie mowy jest już włączone
            if (this.isListening) {
                this.recognition.stop();
                this.isListening = false;
                this.$emit('input', this.transcription);
                return;
            }

            // Włączanie rozpoznawania mowy
            this.recognition.start();
            this.isListening = true;
        },
    },
}
</script>