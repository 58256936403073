<template>
    <!-- <div class="modal" :id="id">
        <div class="modal-dialog" :class="class_other">
            <div v-if="modal" class="modal-content">
                <div v-if="title" class="modal-header">
                    <h4 class="modal-title" v-html="title"></h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <slot></slot>
            </div>
        </div>
    </div> -->


    <div class="offcanvas offcanvas-end" tabindex="-1" :id="id" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel" v-html="title"></h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div v-if="modal" class="offcanvas-body">
            <slot></slot>
        </div>
        <div v-if="!hideFooter" class="offcanvas-footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
import { Offcanvas } from 'bootstrap';
export default {
    props: ['value', 'title', 'class_other', 'hideFooter'],
    data: function () {
        return {
            id: 'modal' + Math.round(Math.random() * 100000),
            modal: null,
        }
    },
    mounted() {
        document.body.append(document.getElementById(this.id));
        this.modal = new Offcanvas(document.getElementById(this.id))
        let vm = this;
        document.getElementById(this.id).addEventListener('hidden.bs.offcanvas', function () {
            vm.triggerModal(false);
        })
        this.triggerModal(this.value);
    },
    methods: {
        triggerModal: function (value) {
            if (value == true) {
                this.modal.show();
            } else {
                this.modal.hide()
                this.$emit('hide');
            }
            this.$emit('input', value);
        }
    },
    watch: {
        value: function () {
            this.triggerModal(this.value);
        }
    },

    onDestroy: function () {
        document.getElementById(this.id).remove();
    }
}
</script>
<style>
.modal-xxl {
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 10px !important;
}

.offcanvas {
    visibility: visible !important;
}
</style>