import draw2d from 'draw2d'

import canvg from 'canvg-browser'

/**
 * @class
 *
 * Converts the canvas document into a PNG Image.
 *
 * @example
 *    // example how to create a PNG image and set an
 *    // image src attribute.
 *    //
 *    let writer = new draw2d.io.png.Writer();
 *    writer.marshal(canvas, function(png){
 *        window.$("#preview").attr("src",png);
 *    });
 *
 * @author Andreas Herz
 * @extends draw2d.io.Writer
 */
export default draw2d.io.Writer.extend(
    /** @lends draw2d.io.png.Writer */
    {

        init: function () {
            this._super()
        },

        /**
         *
         * Export the content to a PNG image. The result can be set as <b>src="...."</b> because
         * the result is encoded as data source url <b>data:image/png;base64....</b>
         * <br>
         * <br>
         *
         * Method signature has been changed from version 2.10.1 to version 3.0.0.<br>
         * The parameter <b>resultCallback</b> is required and new. The method calls
         * the callback instead of return the result.
         *
         * @param {draw2d.Canvas} canvas
         * @param {Function} resultCallback the method to call on success. The first argument is the dataUrl, the second is the base64 formated png image
         * @param {String} resultCallback.img  The image as data source url <b>data:image/png;base64....</b>
         * @param {String} resultCallback.base64  the image encoded in base64
         * @param {draw2d.geo.Rectangle} cropBoundingBox optional cropping/clipping bounding box
         */
        marshal: function (canvas, resultCallback, cropBoundingBox) {
            // I change the API signature from version 2.10.1 to 3.0.0. Throw an exception
            // if any application not care about this changes.
            if (typeof resultCallback !== "function") {
                throw "Writer.marshal method signature has been change from version 2.10.1 to version 3.0.0. Please consult the API documentation about this issue.";
            }

            let svg = ""
            let canvasState = false

            // the png.Writer can create Snapshots of a single figure too.
            // Didn't work in IE <10
            // @status beta
            // @since 5.5.0
            if (canvas instanceof draw2d.Figure) {
                let figure = canvas
                let origPos = figure.getPosition()
                figure.setPosition(1, 1)
                svg = "<svg xmlns:xlink=\"http://www.w3.org/1999/xlink\" >"
                    + figure.shape.node.outerHTML
                    + "</svg>";
                figure.setPosition(origPos);
                figure.initialWidth = figure.getWidth() + 2
                figure.initialHeight = figure.getHeight() + 2
            }
            // create a snapshot of a complete canvas
            //
            else {
                canvasState = {
                    zoom: canvas.getZoom(),
                    scrollLeft: canvas.getScrollLeft(),
                    scrollTop: canvas.getScrollTop(),
                }
                canvas.setZoom(1.0)
                canvas.hideDecoration()
                svg = (new XMLSerializer()).serializeToString(canvas.getHtmlContainer().find("svg")[0]);
            }

            let canvasDomNode = window.$('<canvas id="canvas_png_export_for_draw2d"></canvas>')
            window.$('body').append(canvasDomNode)
            let fullSizeCanvas = window.$("#canvas_png_export_for_draw2d")[0]
            fullSizeCanvas.width = canvas.initialWidth
            fullSizeCanvas.height = canvas.initialHeight

            canvg("canvas_png_export_for_draw2d", svg, {
                ignoreMouse: true,
                ignoreAnimation: true,
                renderCallback: function () {
                    try {
                        if (canvas instanceof draw2d.Canvas) {
                            if (canvasState) {
                                canvas.setZoom(canvasState.zoom)
                                canvas.setScrollLeft(canvasState.scrollLeft)
                                canvas.setScrollTop(canvasState.scrollTop)
                            }
                            canvas.showDecoration();
                        }

                        if (typeof cropBoundingBox !== "undefined") {
                            let sourceX = cropBoundingBox.x
                            let sourceY = cropBoundingBox.y
                            let sourceWidth = cropBoundingBox.w
                            let sourceHeight = cropBoundingBox.h

                            let croppedCanvas = document.createElement('canvas')
                            croppedCanvas.width = sourceWidth
                            croppedCanvas.height = sourceHeight

                            croppedCanvas.getContext("2d").drawImage(fullSizeCanvas, sourceX, sourceY, sourceWidth, sourceHeight, 0, 0, sourceWidth, sourceHeight);

                            croppedCanvas.toBlob(function (blob) {
                                resultCallback(blob)
                            })
                        } else {
                            fullSizeCanvas.toBlob(function (blob) {
                                resultCallback(blob)
                            })
                        }
                    } finally {
                        canvasDomNode.remove()
                    }
                }
            })
        }
    })
