import draw2d from 'draw2d'

/**
 * @class
 *
 * Inplace editor for draw2d.shape.base.Label
 *
 * @example
 *
 *    var label =  new draw2d.shape.basic.Label({text:"Double Click on me"});
 *
 *    label.installEditor(new draw2d.ui.LabelInplaceEditor({
 *       // called after the value has been set to the LabelFigure
 *       onCommit: window.$.proxy(function(value){
 *           alert("new value set to:"+value);
 *       },this),
 *       // called if the user abort the operation
 *       onCancel: function(){
 *       }
 *    }));
 *
 *    canvas.add(label,50,10);
 *
 * @author Andreas Herz
 * @extends draw2d.ui.LabelEditor
 */
var myEditor = draw2d.ui.LabelEditor.extend(
    /** @lends draw2d.ui.LabelInplaceEditor.prototype */
    {

        NAME: "magico.ui.LabelInplaceEditor",

        init: function (listener) {
            this._super();

            // register some default listener and override this with the handover one
            // eslint-disable-next-line no-use-before-define
            this.listener = extend({ // eslint-disable-line
                onCommit: function () {
                },
                onCancel: function () {
                },
                onStart: function () {
                }
            }, listener); // eslint-disable-line
        },

        /**
         *
         * Trigger the edit of the label text.
         *
         * @param {draw2d.shape.basic.Label} label the label to edit
         */
        start: function (label) {
            this.label = label;

            this.commitCallback = this.commit.bind(this);

            // commit the editor if the user clicks anywhere in the document
            //
            window.$("body").bind("click", this.commitCallback);

            // append the input field to the document and register
            // the ENTER and ESC key to commit /cancel the operation
            //
            this.html = window.$('<div contenteditable="true" style="border: 1px dashed black; padding:5px; color:black;" id="inplaceeditor">');
            this.html.html(label.getText().replace(/\n/g, '<br>'));
            this.html.hide();
            label.setVisible(false);
            window.$("body").append(this.html);

            this.html.autoResize();

            this.html.bind("keyup", function (e) {
                switch (e.which) {
                    // case 13:
                    //     this.commit();
                    //     break;
                    case 27:
                        this.cancel();
                        break;
                }
            }.bind(this));

            this.html.bind("blur", this.commitCallback);

            // avoid commit of the operation if we click inside the editor
            //
            this.html.bind("click", function (e) {
                e.stopPropagation();
                e.preventDefault();
            });

            // Position the INPUT and init the autoresize of the element
            //
            var canvas = this.label.getCanvas();
            var bb = this.label.getBoundingBox();

            bb.setPosition(canvas.fromCanvasToDocumentCoordinate(bb.x, bb.y));

            // remove the scroll from the body if we add the canvas directly into the body
            var scrollDiv = canvas.getScrollArea();
            if (scrollDiv.is(window.$("body"))) {
                bb.translate(canvas.getScrollLeft(), canvas.getScrollTop());
            }

            bb.translate(-1, -1);
            bb.resize(2, 2);

            this.html.css({
                position: "absolute",
                "font-size": (label.getFontSize()* (1 / canvas.getZoom())) + 'px',
                "top": bb.y ,
                "left": bb.x,
                "min-width": bb.w * (1 / canvas.getZoom()),
                "min-height": Math.max(25, bb.h * (1 / canvas.getZoom()))
            });
            this.html.fadeIn(() => {
                this.html.focus();
                this.listener.onStart()
            });
        },

        /**
         *
         * Transfer the data from the editor into the label.<br>
         * Remove the editor.<br>
         *
         * @private
         */
        commit: function () {
            this.html.unbind("blur", this.commitCallback);
            window.$("body").unbind("click", this.commitCallback);
            let data = this.html.html();
            if (!this.html.text()) data = '...'
            var label = data.replace(/<div>/g, '').replace(/<\/div>/g, "\n").replace(/<br>/g, "\n");
            var cmd = new draw2d.command.CommandAttr(this.label, { text: label });
            this.label.getCanvas().getCommandStack().execute(cmd);
            this.label.setVisible(true);
            this.html.fadeOut(() => {
                this.html.remove();
                this.html = null;
                this.listener.onCommit(this.label.getText());
            });
        },

        /**
         *
         * Transfer the data from the editor into the label.<br>
         * Remove the editor.<br>
         * @private
         */
        cancel: function () {
            this.html.unbind("blur", this.commitCallback);
            window.$("body").unbind("click", this.commitCallback);
            this.html.fadeOut(() => {
                this.html.remove();
                this.html = null;
                this.listener.onCancel();
            });

        }
    });

export default myEditor;