import LabelRectangle from './LabelRectangle';
import LabelDiamond from './LabelDiamond';
import LabelCircle from './LabelCircle';
import Image from './Image';
import SVGFigure from './SVGFigure';
import Pin from './Pin';
import RaftSection from './RaftSection';
import myEditorInplaceEditor from '../../../draw2d/ui/MyLabelInplaceEditor';

window.magico.shapes.LabelRectangle = LabelRectangle;
window.magico.shapes.LabelDiamond = LabelDiamond;
window.magico.shapes.LabelCircle = LabelCircle;
window.magico.shapes.Image = Image;
window.magico.shapes.SVGFigure = SVGFigure;
window.magico.shapes.Pin = Pin;
window.magico.shapes.RaftSection = RaftSection;

window.magico.ui.LabelInplaceEditor = myEditorInplaceEditor;